<template>
  <div>
    <AboutUsHero title="Sector" />
    <div class="mx-5">
      <sector v-for="sector of sectors.filter(s => s.pastor)" :key="sector.sectorName" v-bind="sector" />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BCollapse,
  // BNavbarToggle,
  // vBToggle,
  VBToggle
} from "bootstrap-vue";

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";
import Sector from "@/@core/components/sector/Sector.vue";
import SectorImage from "@/assets/images/pages/ucc-campus-leaders.jpg"


import SUNYANI from "@/assets/images/sector-images/SUNYANI.jpg"
import TARKWA from "@/assets/images/sector-images/TARKWA.jpg"
// import UCC from "@/assets/images/sector-images/UCC.jpg"
import BOMPATA from "@/assets/images/sector-images/PS DAN.jpg"
import UPPEREAST from "@/assets/images/sector-images/UPPER EAST.jpg"
import LOWERVOLTA from "@/assets/images/sector-images/LO V.jpg"
import KANESHIE from "@/assets/images/sector-images/KANESHIE.jpg"
import KWADASO from "@/assets/images/sector-images/KWADASO.jpg"
import UPPERWEST from "@/assets/images/sector-images/IMG_5744kg.jpg"
import MANKESIM from "@/assets/images/sector-images/MANKESIM.jpg"
import MAMPONG from "@/assets/images/sector-images/MANPONG.jpg"
import MEDINA from "@/assets/images/sector-images/MEDINA.jpg"
import NORTHERN from "@/assets/images/sector-images/NORTHERN.jpg"
import KOFORIDUA from "@/assets/images/sector-images/PASTOR.jpg"
import TECHIMAN from "@/assets/images/sector-images/PS DWUMAH.jpg"
import UPPERVOLTA from "@/assets/images/sector-images/FCC_1468.jpg"
import SUHUM from "@/assets/images/sector-images/Abey_Multimedia_PsBoakye  14.jpg"
import CAPECOAST from "@/assets/images/sector-images/CAPECOAST.jpg"
import TAKORADI from "@/assets/images/sector-images/TAKORADI.png"




export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
    BButton,
    BCollapse,
    Sector
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      SectorImage,
      tertiarySchools: {
        sunyani: [
          
        ]
      },
      sectors: [
        {
          sectorName: 'SUNYANI SECTOR',
          pastor: 'Ps Joseph Owusu-Boateng',
          wife: 'Mrs. Nana Owusu-Boateng',
          text: `
          The Sunyani Sector, under the leadership of Ps Joseph Owusu-Boateng and Mrs. Nana Owusu-
          Boateng as the Traveling Secretary, boasts a vibrant and growing community of Christian
          believers. The sector comprises 20 tertiary institutions and 26 pre-tertiary coordinating schools,
          making it a dynamic hub for spiritual development and outreach. With a total PENSA
          membership of 9,617, the sector demonstrates a strong commitment to nurturing a sense of
          belonging and spiritual growth among its members. The Sunyani Sector stands as a shining
          example of an inclusive and thriving faith-based community. With its diverse membership and
          commitment to outreach, the sector continues to make a positive impact on the lives of its
          members and the broader community
          `,
          schools: [
          "STU", 
          "UENR-Sunyani",
          "UENR-Dormaa", 
          "SNMTC",
          "Yamfo COH", 
          "BECOLED",
          "Berekum HFNMTC", 
          "Tanoso CHNTC",
          "CUG", 
          "DORMAA-PNMTC", 
          "Ntortroso CON",
          "TEPA Nursing", 
          "Goaso NMTC", 
          "Kwapong NTC",
          "Dadesoaba NMTC", 
          "Sampa NMTC", 
          "JOSCO",
          "St. Williams COE",
          "St Ambrose COH",
          "Drobo HFNMTC"
          ],
          image: SUNYANI,
          stats: [
            "Population of 9,617 Student membership",
            "20 Tertiary Institutions",
            "26 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'UPPER WEST SECTOR',
          pastor: 'Pastor Gordon',
          wife: 'Mrs. Evelyn Ansah',
          text: `
          Upper West Sector has a membership of 3, 572. The sector has a total number of 32 coordinating
          schools including 8 tertiary and 24 pre-tertiary institutions. The sector is privileged to work
          under the supervision of Pastor Gordon and Mrs. Evelyn Ansah, his wife.
          `,
          schools: [],
          image: UPPERWEST,
          stats: [
            "Population of 3,572 Student membership",
            "8 Tertiary Institutions",
            "24 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'LOWER VOLTA SECTOR',
          pastor: 'Pastor Edgar Kwesi Karikari',
          wife: 'Mrs. Pokua Karikari',
          text: `
          Lower Volta Sector operates under three principal administrative areas: Ho, Sogakope and
          Aflao. As at July 2023, the sector could boast of a total membership of 6, 319 students. PENSA
          Lower Volta has an impressive network of 61 schools, including 12 tertiary institutions and 39
          pre-tertiary institution. The sector is under the spiritual guidance of Pastor Edgar Kwesi Karikari,
          who is the Traveling Secretary, alongside his wife, Mrs. Pokua Karikari.
          `,
          schools: [
            "University of Health and Allied Sciences", "Peki College of Education",
            "School of Hygiene", "Akatsi College of Education", "Evangelical Presbyterian College",
            "Ohawu Agricultural College", "Holy Spirit College of Education", "Ho Technical University",
            "Keta Nursing and Midwifery Training College", "Ho Nursing Training", "Amedzofe College of Education",
            "Government Secretariat"
          ],
          image: LOWERVOLTA,
          stats: [
            "Population of 6,319 Student membership",
            "12 Tertiary Institutions",
            "39 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'NORTHEN SECTOR',
          pastor: 'PASTOR RICHARD ALA ANANG',
          wife: 'MRS. MONICA OWUSUAA ANANG',
          text: `
          Northern Sector is made up of all tertiary and pre-tertiary institutions in the Northern
          and Savannah regions of Ghana. Administratively, the sector is divided into three (3) zones;
          Tamale Metro, Salaga and Damongo. PENSA is operational in sixteen (16) tertiary institutions.
          There are twenty-six (26) coordinating pre-tertiary institutions based on proximity. Altogether,
          there are 4,290 students in the sector. Pastor Richard Ala Anang is the Traveling Secretary, with
          full support from his wife, Mrs. Monica Owusuaa Anang.
          `,
          schools: [
            "UDS Tamale", "Vert. College, Pong-Tamale",
            "Tamale NMTC", "EP College of Education, Bimbilla", "Sakasaka Comm Health and Nursing",
            "Gushegu Nursing Training School.", "Damango NTC.", "School of Hygiene",
            "UDS- Nyankpala", "Tamale Technical University.", "Amedzofe College of Education",
            "Bagabaga College of Education", 
            "Tamale College of Education", 
            "Damango Agric College",
            "Kpembe NMTC"
          ],
          image: NORTHERN,
          stats: [
            "Population of 4,290 Student membership",
            "16 Tertiary Institutions",
            "26 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'BOMPATA SECTOR',
          pastor: 'Pastor Daniel Mensah',
          wife: 'Mrs. Gladys Obeng Mensah',
          text: `
          Bompata Sector is located in the Ashanti Region of Ghana. It has a membership of 13,
          325. The sector has a total of 58 coordinating institutions comprising 13 tertiary and 45 pre-
          tertiary institutions. Pastor Daniel Mensah, works as the Traveling Secretary, with his wife, Mrs.
          Gladys Obeng Mensah being a strong pillar in the work.
          `,
          schools: [
            "KSTU", "Patrick's Nursing and midwifery",
            "Kumasi Nursing And Midwifery", "Wesley College of Education", "KESBEN UNIVERSITY",
            "Offinso college of education", "St Louis college of education"
          ],
          image: BOMPATA,
          stats: [
            "Population of 4,290 Student membership",
            "13 Tertiary Institutions",
            "45 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'TARKWA SECTOR',
          pastor: 'Pastor Michael',
          wife: 'Mrs. Rosemary Laryea.',
          text: `
          Tarkwa Sector is under the leadership of Pastor Michael and Mrs. Rosemary Laryea.
          With a total membership of 5, 136, stretching from Tarkwa to Essam Debiso, the sector has 9
          tertiary and 23 pre-tertiary institutions. PENSA Tarkwa sector is known for her annual operation
          “Read your Bible” where all members and alumni attempt to read the Bible in four months. i.e.
          from January to April.
          `,
          schools: [
            "UMAT", "NMTC Tarkwa",
            "WATICO", "Asafo COH", "ENCHICO",
            "Asanco NMTC", "SW NTC", "BLACoE"
          ],
          image: TARKWA,
          stats: [
            "Population of 5,136 Student membership",
            "9 Tertiary Institutions",
            "23 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'MADINA SECTOR',
          pastor: 'Pastor Gabriel',
          wife: 'Mrs. Grace Nti.',
          text: `
          Madina Sector is one of the sectors in the Greater Accra region to be created out of the
          former Greater Accra Sector. It has 11 tertiary institutions and other pre-tertiary institutions as
          well. The sector is headed by Pastor Gabriel and Mrs. Grace Nti.
          `,
          schools: [
            "UPSA", "Accra College of Education",
            "Pantang Nursing Training College", "Pantang Nursing and Midwifery Training Center",
            "Central University", "Ashesi University", "Regional Maritime University", "Teshie Nursing and Midwifery Training College",
            "Entrance University College", "Ada College of Education", "Valley View University"
          ],
          image: MEDINA,
          stats: [
            // Not Updated
            "Population of 5,136 Student membership",
            "11 Tertiary Institutions",
            "23 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'MAMPONG SECTOR',
          pastor: 'Pastor SAMUEL MANU',
          wife: 'MRS. GEORGINA MANU',
          text: `
          Mampong Sector is one of the PENSA sectors in the Ashanti region of Ghana. The sector is
          divided into 4 zonal areas; Ashanti Mampong, Atebubu, Agogo and Garden City.in total, there
          are 45 coordinating pre-tertiary institutions. Pastor Samuel Manu is the Traveling Secretary, with
          support from his wife, Mrs. Georgina Manu.
          `,
          schools: [
            "Aamusted-Mampong", "Atebubu College Of Education",
            "Presbyterian University Ghana", "Mampong Technical College of Education", "Ejura Agric College",
            "Agogo Presbyterian Womens College of Education", "Monico", "NMTC", "Garden City University"
          ],
          image: MAMPONG,
          stats: [
            // Not Updated
            "Population of 4,290 Student membership",
            "9 Tertiary Institutions",
            "45 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'KOFORIDUA SECTOR',
          pastor: 'Ps. Michael Fiifi',
          wife: 'Mrs Augusta Borsie',
          text: `
          Koforidua Sector covers the Eastern region of Ghana. It is divided into administrative
          zones; the Agormanya, New Juaben and Akuapem zones. There are 7 tertiary and 48 pre-tertiary
          institutions. The sector lies under the spiritual guidance of Pastor.
          `,
          schools: [
            "Koforidua Technical University", "Presbyterian College of Education Akropong",
            "Presbyterian Womens College of Education Aburi", "Mount Mary’s College of education",
            "Odomase krobo Nursing and Midwifery Training College",
            "Koforidua Nursing and Midwifery Training College", "Seventh Day Adventist College, Asokore"
          ],
          image: KOFORIDUA,
          stats: [
            // Not Updated
            "Population of 4,290 Student membership",
            "7 Tertiary Institutions",
            "48 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'TECHIMAN SECTOR',
          pastor: 'Pastor Robert Dwumah',
          wife: 'Mrs. Constance Osei Dwumah',
          text: `
          Techiman sector was inaugurated on 4th January 2022, with our Travelling Secretary being
          Pastor Robert Dwumah and wife Mrs. Constance Osei Dwumah. Techiman Sector has twenty-
          four (24) coordinating schools, 6 Tertiary institutions and 18 pre-tertiary institutions. The total
          membership for the tertiary institutions is 871 and 2,416 for the pre-tertiary institutions.
          Summing up both the pre-tertiary institutions and tertiary institutions the total number stands at
          3287.
          `,
          schools: [
            "College of health, Kintampo", "Krobo NMTC",
            "Seikwa NMTC", "Anglican University College",
            "Alfaruq College of Education",
            "Wenchi Agricultural College",
            "Holy Family NMTC"
          ],
          image: TECHIMAN,
          stats: [
            "Population of 3,287 Student membership",
            "18 Tertiary Institutions",
            "6 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'KANESHIE SECTOR',
          pastor: 'Pastor Frank',
          wife: 'Dr. Mrs. Salome Mensah Tandoh.',
          text: `
          Kaneshie can be found in the Greater Accra region of Ghana, with a total membership
          of 5,583. The sector is made up of 23 institutions with 7 being tertiary and 16 pre-tertiary. The
          total membership for the tertiary institutions is 2,730 and 2,854 for the pre-tertiary. The
          Traveling Secretary and wife in charge of the sector is Pastor Frank and Dr. Mrs. Salome
          Mensah Tandoh.
          `,
          schools: [
            "KAAF", "Korle Bu",
            "Accra Technical University",
            "G City Campus",
            "GIJ",
            "GH Media"
          ],
          image: KANESHIE,
          stats: [
            "Population of 5,583 Student membership",
            "7 Tertiary Institutions",
            "16 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'CAPE COAST SECTOR',
          pastor: 'Ps. Blessing Joseph',
          wife: 'Mrs Esther Mansa Annan Quayson',
          text: `
          Cape Sector is located in the Central Region of Ghana with a membership of 7,350 as at
          July,2023. The sector has a total number of 37 coordinating schools, which include 7 tertiary
          institutions with total membership of 1,572 and 37 pre-tertiary school with total membership of
          5,778. The sector is privileged to work under the spiritual guidance and the leadership of Pastor
          Blessing Joseph and Mrs. Esther Annan Mansah Quayson.
          `,
          schools: [
            "Cape Coast Technical University",
            "Cape Coast Nursing and Midwifery Training College",
            "Ankaful Psychiatric Nursing and Midwifery Training College",
            "Ola Teachers Training College",
            "Dunkwa Nursing and Midwifery Training College",
            "Twifo Praso Nursing and Midwifery Training College"
          ],
          image: CAPECOAST,
          stats: [
            "Population of 7,350 Student membership",
            "7 Tertiary Institutions",
            "37 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'KWADASO SECTOR',
          pastor: 'Pastor Isaac Donkoh',
          wife: 'Mrs. Doris Ama Donkoh',
          text: `
          The numerical strength of Kwadaso sector currently is 10,053. There are seven tertiary
          institutions within the sector with a total membership of 2,043 and thirty-two pre tertiary
          institutions also at 8,000 membership currently. Pastor Isaac Donkoh is the Traveling Secretary,
          with support from his wife, Mrs. Doris Ama Donkoh.
          `,
          schools: [
            "Aamusted",
            "Obuasi Knust",
            "Fomena Nmtc",
            "Akrokerri Collegeof Education",
            "Kwadaso Agricultural College",
            "Kokofu Nmtc",
            "Royal Ann Christian College"
          ],
          image: KWADASO,
          stats: [
            "Population of 10,053 Student membership",
            "7 Tertiary Institutions",
            "32 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'TAKORADI SECTOR',
          pastor: 'Ps. Fidelis',
          wife: 'Esther Yeboah',
          text: `
          Takoradi Sector operates within the jurisdiction of the Church of Pentecost from
          Takoradi to Axim in the Western region, and has total membership of 5,493 individuals. PENSA
          Takoradi Sector encompasses an impressive network of 30 schools, including six (6) tertiary
          institutions and twenty-four (24) pre-tertiary establishments.
          `,
          schools: [
            "Takoradi Technical University",
            "University of Mines and Technology_School of Railways and Infrastructure",
            "NMTC Sekondi",
            "NMTC Essiama",
            "Porter's Nursing Training College"
          ],
          image: TAKORADI,
          stats: [
            "Population of 5,493 Student membership",
            "6 Tertiary Institutions",
            "24 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'UPPER EAST SECTOR',
          pastor: 'Ps David',
          wife: 'Mrs Dorcas Arthur',
          text: `
          Upper East Sector is located in the Upper East region of Ghana, with 10 tertiary
          institutions and 41 Pre-Tertiary institutions. The tertiary membership is 1,302 while the Pre-
          Tertiary membership stands at 5,956. Ps. David Arthur is the Traveling Sector, with support from
          his wife, Mrs. Dorcas Aboagyewaa Arthur.
          `,
          schools: [],
          image: UPPEREAST,
          stats: [
            "Population of 5,956 Student membership",
            "10 Tertiary Institutions",
            "41 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'UPPER VOLTA SECTOR',
          pastor: 'Pastor Alex Kojo',
          wife: 'Mrs. Elsie Adjani.',
          text: `
          Upper Volta Sector covers some areas of the Volta region and the whole of Oti region.
          The sector has a total membership of 3,582, which spread across 37 institutions comprising 1,069
          tertiary members and 2,513 for pre-tertiary. The sector has since 2009 to date, been under the
          supervision of Pastor Alex Kojo and Mrs. Elsie Adjani.
          `,
          schools: [
            "University of Health and Allied Sciences",
            "St Francis College of Education",
            "Hohoe NMTC",
            "Jasikan College of Education",
            "Dambai Collegeof Education",
            "Krachi NMTC"
          ],
          image: UPPERVOLTA,
          stats: [
            "Population of 3,582 Student membership",
            "6 Tertiary Institutions",
            "31 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'MANKESSIM SECTOR',
          pastor: 'Pastor Joseph Zoot',
          wife: 'Mrs. Monica Kpemyen-Bamembaya.',
          text: `
          Mankessim Sector is located in the Central region of Ghana. We have a total membership
          of 4,786. The Sector has a total number of 35 coordinating schools, which includes 3 tertiary
          institutions with a membership of 411 and 32 pre-tertiary institutions with a membership of 4, 375.
          The Sector is privileged to work under the spiritual guidance and leadership of Pastor Joseph Zoot
          and Mrs. Monica Kpemyen-Bamembaya.
          `,
          schools: [
            "UEW – Ajumako Campus",
            "Fosu College of Education",
            "College of community health nurses (CCHN), Winneba"
          ],
          image: MANKESIM,
          stats: [
            "Population of 4,786 Student membership",
            "3 Tertiary Institutions",
            "35 Pre-tertiary Coordinating Schools"
          ]
        },
        {
          sectorName: 'SUHUM SECTOR',
          pastor: 'Pastor Sampson Kwadwo',
          wife: 'Mrs. Vivian Boakye',
          text: `
          Suhum sector is located in the Eastern region of Ghana. The sector has a total membership
          of 11, 477 with 1, 679 being tertiary students and 9, 798 being pre-tertiary students. Under the
          pastoral care of Pastor Sampson Kwadwo and Mrs. Vivian Boakye, the Traveling Secretary and
          his wife, the school has 52 coordinating instututions; 9 tertiary and 43 pre-tertiary.
          `,
          schools: [
            "PCE, Kibi",
            "PCE, Abetifi",
            "Methodist College of Education",
            "Atibie NMTC",
            "Holy Family NMTC",
            "Afosu Midwifery and Health Training Assistant",
            "University College of Agric and Env Science",
            "CHNTS, Oda"
          ],
          image: SUHUM,
          stats: [
            "Population of 11,477 Student membership",
            "9 Tertiary Institutions",
            "43 Pre-tertiary Coordinating Schools"
          ]
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-title {
  background-color: #021977;
  color: #ffd801;
  width: 200px;
  display: flex;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-center;
  align-items: center;
}

.rod-accordion {
  margin-left: 130px;
  // margin-right: 500px;
  width: 50vw;
  height: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: gray;
}

.stat {
  background-color: #899ff5;
  border-radius: 5px;
  color: black;
  padding: 5px;
  font-size: 1.25rem;
}
.side-note {
  position: relative;
  bottom: 12%;
  left: 31.5%;
  background-color: #021977;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // border-radius: 15px;
  border-left: 10px solid #ffd801;
  font-weight: bolder;
}

.side-note-title {
  color: white;
  font-size: 15px;
}

.side-note-2 {
  position: relative;
  bottom: 52%;
  left: 135%;
  font-size: medium;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
